import { GetApplyRealTimeStatus } from '@/api/lhReception';

import TimerTask from '@/utils/timerTask';

// 订单实时流转
export default {
  data() {
    return {
      orderRealTimeStatusPending: false, // 加载指示器
      orderRealTimeStatusTask: null, // 定时刷新任务
      orderRealTimeStatusTaskDuration: 1000 * 60, // 定时任务执行间隔
      orderRealTimeStatus: [], // 订单实时流转
    };
  },
  methods: {
    // 加载订单实时流转
    async loadOrderRealTimeStatus() {
      try {
        this.orderRealTimeStatusPending = true;

        const orderRealTimeStatus = await GetApplyRealTimeStatus();

        if (orderRealTimeStatus) {
          // 节点名称预设
          const _nodes = {
            preliminaryHearing: '申请',
            autoAudit: 'AI预审',
            facility: '授信',
            signing: '签约',
            drawMoney: '提款',
          };

          this.orderRealTimeStatus = Object.keys(orderRealTimeStatus).map(
            (key) => {
              return {
                title: _nodes[key],
                name: key,
                sum: +orderRealTimeStatus[key] || 0,
              };
            }
          );
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.orderRealTimeStatusPending = false;
      }
    },
  },
  mounted() {
    this.orderRealTimeStatusTask = new TimerTask({
      task: this.loadOrderRealTimeStatus,
      duration: this.orderRealTimeStatusTaskDuration,
    });
  },
  beforeDestroy() {
    this.orderRealTimeStatusTask.dispose();
  },
};
