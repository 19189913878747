import { GetApplyProvince } from '@/api/lhReception';

import chinaGeoCoordMap from '@/lib/chinaGeoCoordMap';

import TimerTask from '@/utils/timerTask';

const echarts = window.echarts || function () {};

// 省份融资金额
// 中国地图
export default {
  data() {
    return {
      financeBalanceProvincePending: false,
      financeBalanceProvinceTask: null, // 定时刷新任务
      financeBalanceProvinceTaskDuration: 1000 * 60 * 10, // 定时刷新间隔
      financeBalanceProvince: null, // 省份融资金额
      financeBalanceProvinceChart: null, // 图表实例 中国地图
      financeBalanceProvinceChartResize: null, // 图表尺寸调整防抖
      financeBalanceProvinceChartData: [],
      financeBalanceProvinceChartAutoPlay: null, // 地区高亮轮播
      financeBalanceProvinceChartCurrent: -1,
    };
  },
  watch: {
    videoOpen(visible) {
      if (visible) {
        this.stopFinanceBalanceProvinceChartAutoPlay();
      } else {
        this.startFinanceBalanceProvinceChartAutoPlay();
      }
    },
  },
  methods: {
    // 加载各省份融资余额
    async loadFinanceBalanceProvince() {
      try {
        this.stopFinanceBalanceProvinceChartAutoPlay();
        this.cancelFinanceBalanceProvinceEvent();
        this.financeBalanceProvincePending = true;
        this.financeBalanceProvince = await GetApplyProvince();
        setTimeout(() => {
          this.initFinanceBalanceProvinceChart(
            this.parseFinanceBalanceProvinceChartOptions()
          );
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.financeBalanceProvincePending = false;
      }
    },

    // 构造图表数据
    parseFinanceBalanceProvinceChartOptions() {
      const { financeBalanceProvince } = this;

      const data = [...financeBalanceProvince].map((row) => {
        return {
          ...row,
          province: row.province.replace(
            /省|市|维吾尔自治区|回族自治区|壮族自治区|自治区/g,
            ''
          ),
        };
      });

      this.financeBalanceProvinceChartData = [...data].map((row) => {
        return {
          name: row.province,
          value: chinaGeoCoordMap[row.province],
          source: row,
        };
      });

      return {
        geo: {
          map: 'china',
          show: true,
          roam: false,
          zoom: 1,
          aspectScale: 0.8,
          layoutCenter: ['50%', '50%'], //地图位置
          layoutSize: '100%',
          itemStyle: {
            borderWidth: 0.5,
            normal: {
              areaColor: '#AFC3F2',
              shadowColor: '#3D496E',
              shadowOffsetX: 0,
              shadowOffsetY: 14,
              opacity: 1,
            },
            emphasis: {
              areaColor: 'rgba(0,243,255,1)',
              label: {
                show: false,
              },
            },
          },
          // 南海诸岛不使用阴影
          regions: [
            {
              name: '南海诸岛',
              itemStyle: {
                areaColor: 'rgb(0, 243, 255)',
                borderColor: 'rgb(0, 243, 255)',
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                    color: '#009cc9',
                  },
                },
              },
              label: {
                show: false,
              },
            },
          ],
        },
        series: [
          {
            type: 'map',
            mapType: 'china',
            aspectScale: 0.8,
            layoutCenter: ['50%', '50%'], //地图位置
            layoutSize: '100%',
            zoom: 1,
            roam: false,
            z: 1,
            scaleLimit: {
              min: 1,
              max: 2,
            },
            itemStyle: {
              normal: {
                areaColor: '#AFC3F2',
                borderColor: '#3D496E',
                borderWidth: 0.5,
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 12,
                },
              },
              emphasis: {
                areaColor: '#7B91C6',
                borderColor: '#3D496E',
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 14,
                  backgroundColor: 'rgba(0, 0, 0, .8)',
                  borderRadius: 4,
                  padding: [4, 6],
                },
              },
            },
            select: {
              label: {
                show: false,
              },
              itemStyle: {
                areaColor: '#0b59a3',
              },
            },
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo', // 使用地理坐标绘制
            color: '#51E6FB',
            silent: true, // 不可交互
            z: 3,
            zlevel: 1,
            symbol: 'circle',
            symbolSize: 4,
            label: {
              show: false,
              position: 'top',
              formatter: (params) => {
                return [
                  params.name,
                  `商户：${params.data.source.merchantNum}`,
                  `融资余额：${params.data.source.balance}`,
                ].join('\n');
              },
            },
            data: this.financeBalanceProvinceChartData,
          },
          {
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              color: '#51E6FB',
              period: 4,
              trailLength: 0.5,
              symbol: 'circle',
              symbolSize: 4,
            },
            lineStyle: {
              normal: {
                color: '#517FFB',
                width: 1,
                curveness: 0.1,
              },
            },
            data: this.convertDataToChinaGeoCoord([...data]),
          },
        ],
      };
    },

    // 坐标和数据关联
    convertDataToChinaGeoCoord(data, start = '重庆') {
      let res = [];

      // 默认从重庆发出
      const fromCoord = chinaGeoCoordMap[start];

      for (var i = 0; i < data.length; i++) {
        const item = data[i];
        // 目标地址
        const toCoord = chinaGeoCoordMap[item.province];

        if (fromCoord && toCoord) {
          res.push([[...toCoord], [...fromCoord]]);
        }
      }

      return res;
    },

    // 初始化地图
    initFinanceBalanceProvinceChart(options) {
      // 初始化图表
      if (this.financeBalanceProvinceChart) {
        this.financeBalanceProvinceChart.dispose();
      }

      this.financeBalanceProvinceChart = echarts.init(
        this.$refs.financeBalanceProvinceChart
      );

      // 装载图表参数
      this.financeBalanceProvinceChart.setOption(options, true);

      // 事件
      this.startFinanceBalanceProvinceChartAutoPlay();
      this.startFinanceBalanceProvinceEvent();
    },

    // 地图尺寸调整
    handleFinanceBalanceProvinceChartResize() {
      if (this.financeBalanceProvinceChartResize) {
        this.financeBalanceProvinceChartResize = clearTimeout(
          this.financeBalanceProvinceChartResize
        );
      }
      this.financeBalanceProvinceChartResize = setTimeout(() => {
        this.financeBalanceProvinceChart.resize();
        this.financeBalanceProvinceChartResize = clearTimeout(
          this.financeBalanceProvinceChartResize
        );
      }, 100);
    },

    // 开始事件监听
    startFinanceBalanceProvinceEvent() {
      window.addEventListener(
        'resize',
        this.handleFinanceBalanceProvinceChartResize
      );
    },

    // 移除事件监听
    cancelFinanceBalanceProvinceEvent() {
      window.removeEventListener(
        'resize',
        this.handleFinanceBalanceProvinceChartResize
      );
    },

    // 开始自动高亮轮播
    startFinanceBalanceProvinceChartAutoPlay() {
      this.financeBalanceProvinceChartAutoPlay = clearTimeout(
        this.financeBalanceProvinceChartAutoPlay
      );

      const { financeBalanceProvinceChartData } = this;
      const total = financeBalanceProvinceChartData.length;

      this.financeBalanceProvinceChartCurrent += 1;
      if (this.financeBalanceProvinceChartCurrent >= total - 1) {
        this.financeBalanceProvinceChartCurrent = 0;
      }

      const _current =
        financeBalanceProvinceChartData[
          this.financeBalanceProvinceChartCurrent
        ];

      this.financeBalanceProvinceChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
      });
      this.financeBalanceProvinceChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        name: _current.name,
      });

      this.financeBalanceProvinceChartAutoPlay = setTimeout(() => {
        this.startFinanceBalanceProvinceChartAutoPlay();
      }, 1000 * 5);
    },

    // 停止自动高亮轮播
    stopFinanceBalanceProvinceChartAutoPlay() {
      this.financeBalanceProvinceChartAutoPlay = clearTimeout(
        this.financeBalanceProvinceChartAutoPlay
      );
    },
  },
  mounted() {
    this.financeBalanceProvinceTask = new TimerTask({
      task: this.loadFinanceBalanceProvince,
      duration: this.financeBalanceProvinceTaskDuration,
    });
  },
  beforeDestroy() {
    this.financeBalanceProvinceTask.dispose();
    this.cancelFinanceBalanceProvinceEvent();
    this.stopFinanceBalanceProvinceChartAutoPlay();
  },
};
