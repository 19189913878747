<template>
  <div class="screen">
    <!-- 标题 -->
    <div class="title-wrap animate__animated animate__fadeInDown">
      <Title title="霖华大数据平台" />
    </div>

    <!-- 时钟 -->
    <Clock
      class="clock animate__animated animate__delay-1s animate__bounceInDown"
    />

    <!-- 成交金额 -->
    <div
      class="
        user-balance-wrap
        animate__animated animate__delay-1s animate__fadeInUp
      "
    >
      <div class="sc-dv-data sc-dv-border">
        <Spin v-if="applyOverviewPending" />
        <Amount :value="financingBalanceAmount || 888888888888" />
        <div class="title">
          <span>成交金额</span>
        </div>
      </div>
    </div>

    <!-- 左侧 -->
    <div class="side left animate__animated animate__fadeInLeft">
      <div class="row" style="height: 200px">
        <Section title="成交概览">
          <Spin v-if="applyOverviewPending" />
          <div v-if="applyOverview" class="center">
            <el-row
              class="padding-with-title padding-x padding-bottom"
              style="flex: 1"
              :gutter="10"
            >
              <el-col :span="12">
                <DataCounter
                  title="累计进件"
                  :base="
                    applyOverview.incomingFinancing.amount
                      | amount({ fixed: 0 })
                  "
                  :sub="applyOverview.incomingFinancing.number"
                  baseSuffix="元"
                  subSuffix="笔"
                />
              </el-col>
              <el-col :span="12">
                <DataCounter
                  title="累计成交"
                  :base="
                    applyOverview.financingBalance.amount | amount({ fixed: 0 })
                  "
                  :sub="applyOverview.financingBalance.number"
                  baseSuffix="元"
                  subSuffix="笔"
                />
              </el-col>
            </el-row>
          </div>
        </Section>
      </div>
      <div class="row" style="height: 30%">
        <Section title="成交趋势">
          <Spin v-if="applyTrendPending" />
          <div class="center">
            <div class="chart" ref="applyTrendChart"></div>
          </div>
        </Section>
      </div>
      <div class="row" style="height: 25%">
        <Section title="成交TOP3榜单">
          <Spin v-if="applyTopicPending" />
          <div class="center">
            <div class="inner-row" style="padding: 32px 0 0">
              <div class="col col-12">
                <div class="chart" ref="applyTopicRegionChart"></div>
              </div>
              <div class="col col-12">
                <div class="chart" ref="applyTopicCarChart"></div>
              </div>
            </div>
          </div>
          <div class="split-title">
            <span>地区</span>
            <span>车型</span>
          </div>
        </Section>
      </div>
      <div class="row" style="height: 25%">
        <Section title="客户概览">
          <Spin v-if="customerOverviewPending" />
          <div class="center">
            <div class="inner-row" style="padding: 32px 0 0">
              <div class="col col-12">
                <Person
                  suffix="人"
                  :man="customerOverviewSex.man"
                  :woman="customerOverviewSex.woman"
                  :manRatio="customerOverviewSex.manRatio"
                  :womanRatio="customerOverviewSex.womanRatio"
                />
              </div>
              <div class="col col-12">
                <div class="chart" ref="customerOverviewAgeChart"></div>
              </div>
            </div>
          </div>
          <div class="split-title">
            <span>性别</span>
            <span>年龄段</span>
          </div>
        </Section>
      </div>
    </div>

    <!-- 订单实时流转 -->
    <div
      class="middle animate__animated animate__fadeInUp"
      style="margin-bottom: 10px"
    >
      <Section title="订单实时流转">
        <Spin v-if="orderRealTimeStatusPending" />
        <div class="padding-with-title" style="height: 160px">
          <div v-if="orderRealTimeStatus" class="center">
            <StatusProcess :nodes="orderRealTimeStatus" />
          </div>
        </div>
      </Section>
      <div class="padding-bottom"></div>
    </div>

    <!-- 右侧 -->
    <div class="side right animate__animated animate__fadeInRight">
      <div class="row" style="height: 280px">
        <Section
          title="融资概览"
          style="
            background-image: url('/images/2020/bg-0001.png');
            background-repeat: no-repeat;
            background-position: right bottom;
          "
        >
          <Spin v-if="financeOverviewPending" />
          <div class="center">
            <el-row
              class="padding-with-title padding-x padding-bottom"
              :gutter="20"
            >
              <el-col :span="12" class="padding-right">
                <DataCounter
                  title="融资余额"
                  baseSuffix="元"
                  :base="financeOverview.allBalance | amount({ fixed: 0 })"
                />
                <el-divider class="divider" dashed />
                <DataCounterRound
                  title="今日变动"
                  baseSuffix="元"
                  :base="
                    financeOverview.dayBalanceChange | amount({ fixed: 0 })
                  "
                  :separator="false"
                  :showSub="false"
                />
              </el-col>
              <el-col :span="12" class="padding-left">
                <DataCounter
                  title="累计回款"
                  baseSuffix="元"
                  :base="
                    financeOverview.accumulatedCollection.amount
                      | amount({ fixed: 0 })
                  "
                />
                <el-divider class="divider" dashed />
                <DataCounterRound
                  title="今日变动"
                  baseSuffix="元"
                  :base="
                    financeOverview.collectionToday.amount
                      | amount({ fixed: 0 })
                  "
                  :separator="false"
                  :showSub="false"
                />
              </el-col>
            </el-row>
          </div>
        </Section>
      </div>
      <div class="row" style="height: 30%">
        <Section title="融资回款趋势">
          <Spin v-if="financeTrendPending" />
          <div class="chart" ref="financeTrendChart"></div>
        </Section>
      </div>
      <div class="row expand">
        <Section title="月度区域成交排名">
          <Spin v-if="tranAreaAndAgentPending" />

          <!-- 在线经纪人活跃数 -->
          <DataNumber
            class="active-agent"
            prefix="在线经纪人活跃数"
            suffix="人"
            :value="merchantAgenTotal"
          />

          <!-- 滚动表格 -->
          <div class="center padding-with-title">
            <MarqueeTable
              v-if="tranAreaAndAgent.length"
              :data="tranAreaAndAgent"
              :columns="tranAreaAndAgentColumns"
            />
            <span v-else class="empty-text">本月暂无数据</span>
          </div>
        </Section>
      </div>
    </div>

    <!-- 地图 -->
    <div class="map-wrap">
      <div class="center">
        <Spin v-if="financeBalanceProvincePending" />
        <RatioBox :ratio="1">
          <div class="chart" ref="financeBalanceProvinceChart"></div>
        </RatioBox>
        <!-- 南海地图补充
        <div class="china-map-nanhai-wrap">
          <div class="china-map-nanhai">
            <div class="inner" />
          </div>
        </div>
         -->

        <!-- 雷达图 -->
        <div class="radar">
          <Spin v-if="applyRegionPending" />
          <transition enter-active-class="animate__animated animate__backInUp">
            <div class="chart" ref="applyRegionChart"></div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 页面修饰 -->
    <div class="adorn adorn-bottom">
      <i v-for="i in 10" :key="i" />
    </div>
    <div class="adorn adorn-side adorn-side-left" />
    <div class="adorn adorn-side adorn-side-right" />

    <!-- 宣传视频播放控制 -->
    <div
      v-if="false"
      :class="['video-controls', videoPanelActive ? 'active' : '']"
    >
      <h2>宣传视频控制器</h2>
      <div class="interval">
        <h4>视频播放间隔</h4>
        <ul>
          <li
            v-for="(item, index) in videoIntervalPreset"
            :key="index"
            :class="videoInterval === item.value ? 'active' : ''"
            @click="videoInterval = item.value"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <!-- 播放或暂停播放控制 -->
      <button class="handle pause" @click="$refs.video.toggle()">播放</button>
      <!-- 停用自动计时播放 -->
      <button
        :class="['handle', 'stop', videoEnable ? '' : 'enable']"
        @click="
          videoEnable = !videoEnable;
          videoPanelActive = !videoPanelActive;
        "
      >
        {{ videoEnable ? '停用' : '启用' }}
      </button>
      <button class="toggle" @click="videoPanelActive = !videoPanelActive">
        {{ videoPanelActive ? '收起控制器' : 'VIDEO CONTROL' }}
      </button>
    </div>

    <!-- 宣传视频 -->
    <!-- <Video
      ref="video"
      :enable="videoEnable"
      :interval="videoInterval"
      :autoplay="false"
      @on-open="handleVideoToggle(true)"
      @on-close="handleVideoToggle(false)"
    /> -->
  </div>
</template>

<script>
import mixinApplyOverview from './mixins/applyOverview';
import mixinOrderRealTimeStatus from './mixins/orderRealTimeStatus';
import mixinApplyTrend from './mixins/applyTrend';
import mixinApplyTopic from './mixins/applyTopic';
import mixinCustomerOverview from './mixins/customerOverview';
import mixinFinanceOverview from './mixins/financeOverview';
import mixinFinanceTrend from './mixins/financeTrend';
import mixinFinanceBalanceProvince from './mixins/financeBalanceProvince';
import mixinTranAgent from './mixins/tranAgent';
import mixinApplyRegion from './mixins/applyRegion';
import mixinVideo from './mixins/video';

export default {
  name: 'LinhuaReception2020',
  mixins: [
    mixinApplyOverview,
    mixinOrderRealTimeStatus,
    mixinApplyTrend,
    mixinApplyTopic,
    mixinCustomerOverview,
    mixinFinanceOverview,
    mixinFinanceTrend,
    mixinFinanceBalanceProvince,
    mixinTranAgent,
    mixinApplyRegion,
    mixinVideo,
  ],
  components: {
    RatioBox: () => import('./components/RatioBox.vue'),
    Spin: () => import('./components/Spin.vue'),
    Section: () => import('./components/Section.vue'),
    Title: () => import('./components/Title.vue'),
    Clock: () => import('./components/Clock.vue'),
    Amount: () => import('./components/Amount.vue'),
    Person: () => import('./components/Person.vue'),
    DataCounter: () => import('./components/DataCounter.vue'),
    DataCounterRound: () => import('./components/DataCounterRound.vue'),
    DataNumber: () => import('./components/DataNumber.vue'),
    StatusProcess: () => import('./components/StatusProcess'),
    MarqueeTable: () => import('./components/MarqueeTable'),
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(
      () => {
        console.log('执行刷新');

        // window.location.reload();
        const somd5 = this.GetQueryString('so');

        if (somd5) {
          window.location.href = `${window.location.origin}${
            window.location.pathname
          }?so=${somd5}&refreshTime=${new Date().getTime()}`;
        } else {
          window.location.href = `${window.location.origin}${
            window.location.pathname
          }?refreshTime=${new Date().getTime()}`;
        }
      },
      2 * 60 * 60 * 1000
      // 5 * 1000
    );
  },
  methods: {
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

    // 错误消息处置
    handleError(error) {
      console.error('运行错误：' + error);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
