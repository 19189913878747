import {
  GetApplyAllBalance,
  GetApplyDayBalanceChange,
  GetApplyAccumulatedCollection,
  GetApplyCollectionToday,
} from '@/api/lhReception';

import TimerTask from '@/utils/timerTask';

// 融资回款概览
export default {
  data() {
    return {
      financeOverviewPending: false, // 加载指示器
      financeOverviewTask: null, // 定时刷新任务
      financeOverviewTaskDuration: 1000 * 60, // 定时任务刷新间隔
      financeOverview: {
        allBalance: 0,
        dayBalanceChange: 0,
        accumulatedCollection: 0,
        collectionToday: 0,
      }, // 融资回款概览
    };
  },
  methods: {
    // 加载融资回款概览数据
    async loadFinanceOverview() {
      try {
        this.financeOverviewPending = true;

        // 融资金额
        this.financeOverview.allBalance = await GetApplyAllBalance();

        // 今日变动
        this.financeOverview.dayBalanceChange =
          await GetApplyDayBalanceChange();

        // 累计回款
        this.financeOverview.accumulatedCollection =
          await GetApplyAccumulatedCollection();

        // 今日回款
        this.financeOverview.collectionToday = await GetApplyCollectionToday();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.financeOverviewPending = false;
      }
    },
  },
  mounted() {
    this.financeOverviewTask = new TimerTask({
      task: this.loadFinanceOverview,
      duration: this.financeOverviewTaskDuration,
    });
  },
  beforeDestroy() {
    this.financeOverviewTask.dispose();
  },
};
