<template>
  <div class="login-wrap">
    <el-form v-model="form" class="login-form">
      <div class="title">霖华大屏可视化系统</div>
      <div class="title-sub">Visualization Platform</div>
      <el-form-item>
        <el-input
          v-model="form.username"
          maxlength="16"
          placeholder="用户名/工号"
          prefix-icon="el-icon-user"
          :disabled="pending"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.password"
          maxlength="16"
          placeholder="密码"
          prefix-icon="el-icon-lock"
          :disabled="pending"
          show-password
        />
      </el-form-item>
      <el-form-item>
        <el-button
          v-loading="pending"
          type="primary"
          style="width: 100%"
          @click="handleSubmit"
        >
          登&nbsp;&nbsp;录
        </el-button>
      </el-form-item>
      <div class="copyright">霖华 &copy; 版权所有</div>
    </el-form>
  </div>
</template>

<script>
import { ApiLogin } from '@/api/App';

import { setUser } from '@/utils/auth';

// 登录表单
const FORM_DEFAULT = {
  username: null,
  password: null,
};

// 登录
export default {
  name: 'Login',
  data() {
    return {
      pending: false,
      timer: null,
      form: { ...FORM_DEFAULT },
    };
  },
  methods: {
    async handleSubmit() {
      if (this.pending) return;

      try {
        this.pending = true;

        // 登录 BI 系统
        const data = await ApiLogin({ ...this.form });

        // 缓存用户信息
        setUser(data);

        this.$message({
          type: 'success',
          duration: 1500,
          message: '登录成功 ···',
          onClose() {
            window.location.href = '/';
          },
        });
      } catch (error) {
        this.$message({
          type: 'error',
          message: error,
        });
      } finally {
        this.pending = false;
      }
    },

    // 键盘事件监听
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.handleSubmit();
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown, false);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown, false);
    this.timer = clearTimeout(this.timer);
  },
  deactivated() {
    this.timer = clearTimeout(this.timer);
  },
};
</script>

<style>
body {
  background-color: #000;
  background-image: url(/images/login-bg.jpeg?v=2.0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
/deep/.el-form-item:last-of-type {
  margin-bottom: 0;
}

.login-wrap {
  background-color: rgba(#000, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.login-form {
  background-color: #ffffff;
  box-shadow: 0 32px 64px rgba(#000, 0.5);
  border-radius: 0 64px 0 64px;
  margin: 0 auto;
  padding: 22px 40px;
  width: 400px;
}

.title {
  color: #09f;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.title-sub {
  color: #999;
  font-size: 12px;
  margin-bottom: 42px;
  text-align: center;
}

.copyright {
  color: #999;
  font-size: 12px;
  padding: 20px;
  text-align: center;
}
</style>
