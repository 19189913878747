const moment = window.moment || function () {};

// 定时执行方法
const TimerTask = function (options = {}) {
  // 合并参数
  this.options = {
    ...TimerTask.DEFAULTS,
    ...options,
  };

  // 初始化
  this.init();
};

TimerTask.prototype = {
  // 初始化
  init() {
    const { options } = this;

    // 是否可执行操作
    this.canExecute = true;

    // options.task 必须是一个 Function
    if (!(options.task instanceof Function))
      throw 'TimerTask.options.task 必须是一个 Function';

    // 时间修正
    if (!options.duration) {
      this.canExecute = false;
    } else {
      try {
        options.duration = Math.min(
          parseInt(options.duration, 10) || 1000,
          Number.MAX_SAFE_INTEGER
        );
      } catch (error) {
        console.warn(
          'TimerTask.options.duration 参数格式错误，支持使用数字格式！'
        );
      }
    }

    // 修正执行次数
    try {
      options.times = Math.min(
        parseInt(options.times, 10) || 0,
        Number.MAX_SAFE_INTEGER
      );
    } catch (error) {
      console.warn('TimerTask.options.times 参数格式错误，支持使用数字格式！');
    }

    // 初始定时器
    this.timer = undefined;
    // 初始化执行次数
    this.times = 0;

    // 自动开始
    if (options.auto) {
      this.start();
    } else {
      this.timer = setTimeout(() => {
        this.start();
      }, options.duration);
    }
  },

  // 开始
  async start() {
    const { options, canExecute } = this;

    // 清除上个定时器
    this.stop();

    // 参数错误，参数错误时，需要刷新页面重启任务
    if (!canExecute) {
      console.error('参数错误，请刷新页面！');
      return;
    }

    // 不在定时任务有效区间时，每隔 5 秒验证一次
    if (!this.inTimeRange()) {
      console.warn('不在有效任务刷新区间！');
      this.timer = setTimeout(() => {
        this.start();
      }, 1000 * 5);
      return;
    }

    // 执行操作
    if (
      Object.prototype.toString.call(options.task) === '[object AsyncFunction]'
    ) {
      await options.task.call();
    } else {
      options.task.call();
    }

    // 是否可继续执行
    if (options.times !== 0) {
      // 累加执行次数
      this.times += 1;

      // 到达执行次数停止执行
      if (this.times >= options.times) {
        options.onTimesEnd(this);
        return;
      }
    }

    // 未设置执行次数时，继续延时执行
    this.timer = setTimeout(() => {
      this.start();
    }, options.duration);
  },

  // 停止
  stop() {
    this.timer = clearTimeout(this.timer);
  },

  // 销毁
  dispose() {
    this.reset();
    this.stop();
  },

  // 重置
  reset() {
    // 重置执行次数
    this.times = 0;
  },

  // 初始化任务实现有效时间段
  initTimeRange() {
    const { timeRange } = this.options;

    if (!Array.isArray(timeRange) || timeRange.length < 2) return false;

    const times = [...timeRange].map((set) => {
      return moment(`${moment().format('YYYY-MM-DD')} ${set}`).valueOf();
    });

    return {
      start: times[0],
      end: times[1],
    };
  },

  // 当前是否在有效执行时间区间
  inTimeRange() {
    const _timeRange = this.initTimeRange();

    // 未设置限定区间
    if (!_timeRange) return true;

    // 比较时间
    const now = moment().valueOf();
    return _timeRange.start <= now && now <= _timeRange.end;
  },
};

// 默认参数
TimerTask.DEFAULTS = {
  task: () => {}, // 执行的任务
  duration: 1000, // 执行间隔，单位 毫秒
  auto: true, // 调用后立即开始执行
  times: 0, // 执行次数，自动执行到指定次数后自动停止，为 0 时无限执行
  onTimesEnd: () => {}, // 执行次数完成后执行的回调
  timeRange: ['08:30:00', '21:00:00'], // 任务执行有效时间段，[开始时分秒,结束时分秒]，如：['8:30:00','21:30:00']
};

export default TimerTask;
